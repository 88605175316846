<template>
  <div>
    <Navigator />
    <div class="hbody">
      <div class="sec1">
        <carousel v-if="slides !== 'No se encontraron coincidencias'" :perPage="1" autoplay navigationEnabled loop
          :paginationEnabled="false">
          <slide v-for="(s, i) in slides" :key="i">
            <div class="slide-item mobile" :style="{
              'background-image':
                'url(' +
                urlpath.url() +
                '/get-slide-image/' +
                s.image_mv +
                ')',
            }">
              <div class="slide-cont">
                <a :href="s.link">Visite aquí</a>
              </div>
            </div>
            <div class="slide-item pc" :style="{
              'background-image':
                'url(' +
                urlpath.url() +
                '/get-slide-image/' +
                s.image +
                ')',
            }">
              <div class="slide-cont" v-if="s.link">
                <a :href="s.link">Visite aquí</a>
              </div>
            </div>
          </slide>
        </carousel>
        <div class="val_no" v-else>SLIDES VACÍO</div>
      </div>
      <div class="sec2">
        <div class="header">LO MÁS DESTACADO</div>
        <div v-if="blogs !== 'No se encontraron coincidencias.'">
          <div class="items-c">
            <div v-for="(b, i) in blogs" :key="i" class="itm">
              <div class="post-c">
                <a :href="'/blog-post/' + b.url">
                  <img :src="urlpath.url() + '/blog-img/' + b.image
                    " alt="post" class="post-img" />
                </a>
                <a :href="'/blog-post/' + b.url">
                  <div class="info-c">
                    <p class="title">{{ b.title }}</p>
                    <p class="date">{{ b.dateblog }}</p>
                  </div>
                </a>
                <a :href="'/blog-post/' + b.url" class="btn">
                  <p>LEER MÁS</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="val_no" v-else>BLOGS VACÍO</div>
      </div>

      <div class="banner-sec" v-if="banner !== 'No se encontraron coincidencias.'">
        <a :href="banner.link" target="_blank" rel="noopener noreferrer" class="banner">
          <img :src="urlpath.url() +
            '/get-banner-image/' +
            banner.image
            " alt="banner" />
        </a>
      </div>
      <div v-else></div>

      <!-- <div class="sec3">
        <div class="collab-c">
          <p>COLABORADORES</p>
          <a href="/colaboradores">CONÓCELOS</a>
        </div>
        <div class="collab-c">
          <p>REVISTA DIGITAL</p>
          <a
            href="https://issuu.com/revistafoodiemx/docs/revista_num1"
            target="_blank"
            rel="noopener noreferrer"
            >IR A ISSUU</a
          >
        </div>
      </div>-->
      <div class="sec4">
        <div class="sec4-c">
          <img src="../assets/food1.png" alt="food" class="food" />
          <form @submit.prevent="suscribirse(email)" class="dflx fdc aic">
            <p class="titulo">SUSCRÍBETE A NUESTRO NEWSLETTER</p>
            <input type="email" name="email" id="email" placeholder="Email" v-model="email" />
            <div v-if="status == 'success'" class="msg msg_success">
              <p class="">Suscripción exitosa!</p>
            </div>
            <div v-if="status == 'error'" class="msg  msg_error">
              <p class="">{{ message }}</p>
            </div>
            <button type="submit" class="cp">SUSCRIBIRSE</button>
            <div class="check-c aic dflx">
              <input type="checkbox" name="accept" id="accept" v-model="isacepted" />
              <p>Si continúas, aceptas la política de privacidad</p>
            </div>
          </form>
        </div>
      </div>
      <div class="sec5">
        <div class="sec5-c">
          <p class="titulo">INSTAGRAM</p>
          <p>
            ¿Quieres enterarte de nuestro día a día?, síguenos en Instagram y
            conoce más de nosotros
          </p>
          <div class="instagram-grid">
            <vue-instagram token="accessTokenHere" :count="3" :tags="['hashtag1', 'hashtag2']" mediaType="image">
              <template slot="feeds" slot-scope="props">
                <li class="fancy-list"> {{ props.feed.link }} </li>
              </template>
              <template slot="error" slot-scope="props">
                <div class="fancy-alert"> {{ props.error.error_message }} </div>
              </template>
            </vue-instagram>
            <!--<a class="image-c" href="">
              <div></div>
            </a>--->
          </div>
          <a href="https://www.instagram.com/revistafoodie/" class="btn" target="_blank" rel="noopener noreferrer">
            <div class="insbtn">@REVISTAFOODIE</div>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import Footer from "../components/items-page/Footer";
import dayjs from "dayjs";

let urlpath = require("../global/url");
export default {
  name: "Home",
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {
      status: "",
      message: "",
      email: "",
      isacepted: false,
      urlpath,
    };
  },
  created() {
    this.getAllInfoSld("slide");
    this.getAllInfoBlo("blog");
    this.getAllInfoBnr("banner");
  },
  computed: {
    slides() {
      return this.$store.getters["slide/data"];
    },
    blogs() {
      let data = this.$store.getters["blog/data"].filter(element => element.statusblog !== 'OCULTO' && !isNaN(element.orden));
      if (!Array.isArray(data)) return []
      data.sort((a, b) => {
        return a.orden - b.orden;
      });
      return data.slice(0, 6);
    },
    banner() {
      let arr = this.$store.getters["banner/data"];
      if (arr == "No se encontraron coincidencias") {
        return 'No se encontraron coincidencias.';
      } else {
        let array = arr.filter((arr) => arr.section == "home");
        return array[0];
      }/*
      return arr*/
    },
  },
  methods: {
    ...mapActions("slide", ["getAllInfoSld"]),
    ...mapActions("banner", ["getAllInfoBnr"]),
    ...mapActions("blog", ["getAllInfoBlo"]),

    suscribirse: async function (email) {
      if (this.isacepted == true) {
        let result = await this.$store.dispatch("main/addSuscriptor", {
          email: email,
        });

        if (result.status == "success") {
          this.msg = "Gracias por subscribirte a nuestro boletín.";
          this.status = "success";
          this.email_form = ""
        } else {
          this.message = result.message;
          this.status = "error";
          this.delStatus();
        }
      } else {
        this.message = "Acepta los términos y condiciones";
        this.status = "error";
        this.delStatus();
      }
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 3000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
    },
  },
};
</script>
<style>
.VueCarousel-navigation {
  display: none;
}

/**sec2 */
.sec2 {
  margin: 14.493vw 0;
}

.sec2 .header {
  width: 90%;
  margin: 14.493vw auto 0;
  border-top: solid 0.725vw #a6a6a6;
  border-bottom: solid 0.725vw #a6a6a6;
  padding: 7vw 0;
  text-align: center;
  color: #000;
  font-family: var(--RedHat);
  font-size: 4vw;
  font-weight: 700;
}

/**banner */
.banner-sec {
  background-color: #a6a6a6;
  width: 92.754vw;
  height: auto;
  margin: auto;
}

.banner-sec img {
  width: 100%;
}

/**sec3 */
.sec3 {
  margin-top: 9.1831vw;
}

.collab-c {
  width: 100%;
  height: 96.618vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.collab-c:nth-child(1) {
  margin-bottom: 2.415vw;
  background-image: url("../assets/bg3.png");
}

.collab-c:nth-child(2) {
  margin-bottom: 2.415vw;
  background-image: url("../assets/bg4.png");
}

.collab-c p {
  padding-bottom: 14.493vw;
  font-size: 4vw;
  font-weight: 700;
  color: white;
  font-family: var(--RedHat);
  margin: 0;
}

.collab-c a {
  padding: 5.072vw 7.488vw;
  border: 0.966vw solid white;
  font-size: 4.203vw;
  color: white;
  font-weight: 700;
  font-family: var(--RedHat);
  transition: 0.5s;
}

.collab-c a:hover {
  background-color: white;
  color: rgb(0, 0, 0);
}

/**sec4 */
.sec4-c {
  padding: 14.493vw 0;
  width: 89.855vw;
  margin: auto;
  border-bottom: 0.725vw solid #a6a6a6;
}

.sec4-c .food {
  display: none;
}

.sec4-c .titulo {
  font-size: 5vw;
  line-height: 5.4vw;
  font-family: var(--RedHat);
  font-weight: 700;
  text-align: center;
  width: 68%;
  margin-bottom: 12.077vw;
}

.sec4-c input[type="email"] {
  width: 54.589vw;
  padding: 8.454vw;
  border: 0.242vw solid #ddd;
  background-color: #f4f4f4;
  font-size: 4vw !important;
}

.sec4-c input[type="email"]::placeholder {
  color: #000;
}

.sec4-c button {
  margin-top: 8.454vw;
  width: 72.464vw;
  height: auto;
  padding: 8.454vw 21.256vw !important;
  font-size: 4vw !important;
  background-color: #000;
  border: 0.966vw solid #000;
  color: #fff;
  font-family: var(--RedHat);
  font-weight: 700;
  transition: 0.5s;
}

.sec4-c button:hover {
  border: 0.966vw solid #000;
  background-color: white;
  color: #000;
}

.check-c {
  margin-top: 7.246vw;
}

.check-c input[type="checkbox"] {
  height: 6.039vw;
  width: 6.039vw;
}

.check-c p {
  font-size: 3.382vw;
  padding-left: 1vw;
  margin: 0;
}

/***sec5 */
.sec5-c .titulo {
  font-size: 5vw;
  line-height: 5.4vw;
  font-family: var(--RedHat);
  font-weight: 700;
  text-align: center;
  margin-top: 12.077vw;
  margin-bottom: 3.5vw;
  color: #000000;
}

.sec5-c p {
  font-size: 3.5vw;
  line-height: 4.5vw;
  font-family: var(--RedHat);
  text-align: center;
  font-weight: 700;
  color: #7c7c7c;
}

/**sec6 */
.sec5-c {
  width: 100%;
  padding-bottom: 28vw;
}

.instagram-grid .image-c div {
  width: 100%;
  height: 100vw;
  background-color: gainsboro;
  border-bottom: 0.5vw solid white;
}

.sec5-c a.btn {
  margin: 0 auto 0;
  width: fit-content;
  display: flex;
}

.sec5-c .insbtn {
  margin: 0;
  width: fit-content;
  height: auto;
  padding: 10.87vw 16.908vw !important;
  font-size: 4vw !important;
  background-color: #000;
  border: 0.966vw solid #000;
  color: #fff;
  font-family: var(--RedHat);
  font-weight: 700;
  transition: 0.5s;
}

.sec5-c .insbtn:hover {
  border: 0.966vw solid #000;
  background-color: white;
  color: #000;
}

@media (min-width: 768px) {
  .VueCarousel-navigation {
    display: block;
  }

  .sec2 {
    margin: 3.125vw 0;
  }

  .sec2 .header {
    width: 90%;
    margin: 3.125vw auto 0;
    border-top: solid 0.156vw #a6a6a6;
    border-bottom: solid 0.156vw #a6a6a6;
    padding: 4.167vw 0;
    font-size: 2.969vw;
    line-height: 2.969vw;
  }

  /**sec3 */
  .sec3 {
    margin-top: 3.1831vw;
    display: flex;
    justify-content: space-between;
  }

  .collab-c {
    width: 49.4vw;
    height: 23.438vw;
    background-position: top;
  }

  .collab-c p {
    padding-bottom: 2.493vw;
    font-size: 3vw;
    margin: 0;
  }

  .collab-c a {
    padding: 2.083vw 3.646vw;
    border: 0.26vw solid white;
    font-size: 1.5vw;
    transition: 0.5s;
  }

  /**sec4 */
  .sec4-c .titulo {
    font-size: 2.7vw;
    line-height: 3vw;
    width: 71%;
    margin-bottom: 1.563vw;
  }

  .sec4-c {
    padding: 4.166666666666666VW 0;
    width: 90%;
    border-bottom: 0.26vw solid #a6a6a6;
  }

  .sec4-c .food {
    display: block;
    width: 33.75vw;
    position: absolute;
    left: -3.1vw;
  }

  .sec4-c form {
    width: 46vw;
    margin-left: auto;
    margin-right: 5.542vw;
  }

  .sec4-c input[type="email"] {
    width: 42.813vw;
    padding: 1.042vw;
    border: 0.142vw solid #ddd;
    font-size: 1.51vw !important;
  }

  .sec4-c button {
    margin-top: 2.865vw;
    width: 17.719vw;
    padding: 2.604vw 3.646vw !important;
    font-size: 1.51vw !important;
    border: 0.142vw solid #000;
  }

  .sec4-c button:hover {
    border: 0.142vw solid #000;
  }

  .check-c {
    margin-top: 1.563vw;
  }

  .check-c input[type="checkbox"] {
    height: 1.302vw;
    width: 1.302vw;
  }

  .check-c p {
    font-size: 0.729vw;
    line-height: 0.729vw;
    padding-left: 0.3vw;
    margin: 0;
  }

  /**sec5 */
  .sec5-c {
    width: 100%;
    padding-bottom: 7vw;
  }

  .sec5-c .titulo {
    text-align: center;
    font-size: 3vw;
    line-height: 3vw;
    margin: 4.167vw auto 0.781vw;
  }

  .sec5-c p {
    text-align: center;
    font-size: 1.302vw;
    line-height: 2.302vw;
    margin: 0.781vw auto 4.688vw;
    width: 37%;
  }

  .instagram-grid {
    display: flex;
  }

  .instagram-grid .image-c {
    /*width: 33vw;
    height: 33.854vw;*/
    margin: 0;
    width: 33%;
  }

  .instagram-grid .image-c div {
    width: 100%;
    height: 33.854vw;
    background-color: gainsboro;
    border-bottom: 0.26vw solid white;
  }

  .sec5-c .insbtn {
    margin: 0 auto 0;
    padding: 2.604vw 4.167vw !important;
    font-size: 1.51vw !important;
    border: 0.26vw solid #000;
  }

  .sec5-c .insbtn:hover {
    border: 0.26vw solid #000;
  }
}
</style>
